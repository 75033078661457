<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback />
        {{ $t('report_dispensing_drug') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters :default-status='2' @onSendDate='addPayload'></DateFilters>
      <v-row class='px-2'>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-text-field
            v-model='searchtext'
            outlined
            dense
            :label="`${$t('search')} ${$t('pressEnterForSearch')}`"
            @keypress.enter='addPayload(payload.start, payload.end)'
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.order_id_pri`]='{ item }'>
          <router-link
            v-if='item.order_id_pri'
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.order_id }}
          </router-link>
          <router-link
            v-if='item.serve_id'
            :to="{ name: 'showDetailService', params: { id: item.serve_id } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.serve_code }}
          </router-link>
          <router-link
            v-if='item.transfer_id_pri'
            :to="{ name: 'stockUpdateDetail', params: { id: item.transfer_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.transfer_id }}
          </router-link>
        </template>
        <template v-slot:[`item.drug_id`]='{ item }'>
          <router-link
            :to="{ name: 'drugDetail', params: { id: item.drug_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.drug_id }}
          </router-link>
        </template>
        <template v-slot:[`item.transfer_out`]='{ item }'>
          <span :class="item.transfer_out == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.transfer_out }}
          </span>
        </template>
        <template v-slot:[`item.sum_out`]='{ item }'>
          <span :class="item.transfer_out == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.sum_out }}
          </span>
        </template>
        <template v-slot:[`item.total`]='{ item }'>
          <span :class="item.transfer_out == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.total }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import Comeback from '../Comeback.vue'
import { reportDisPensing, removeComma } from '../useExcel'
import { sumdate } from '@/plugins/filters'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  setup() {
    const searchtext = ref('')
    const XLSX = require('xlsx')
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      {
        text: i18n.t('code'),
        value: 'drug_id',
        width: 100,
      },
      {
        text: i18n.t('group'),
        value: 'drug_category_name',
        width: 80,
      },
      {
        text: i18n.t('name'),
        value: 'drug_name',
        width: 200,
      },
      {
        text: i18n.t('cost_price'),
        value: 'drugorder_cost',
        width: 120,
        align: 'end',
      },
      // {
      //   text: i18n.t('date'),
      //   value: 'date',
      //   width: 170,
      // },
      {
        text: i18n.t('receipt'),
        value: 'receipt',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('cut_course'),
        value: 'cut_course',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('transfer_out'),
        value: 'transfer_out',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('overall'),
        value: 'sum_out',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('unit'),
        value: 'drug_unit',
        width: 120,
      },
      {
        text: i18n.t('net_amount'),
        value: 'total',
        width: 120,
        align: 'end',
      },
    ])
    const dataTableList = ref([])
    const options = ref({})
    const loading = ref(true)
    const header = ref('')
    const payload = ref({})
    const exportLoading = ref(false)
    const data2 = ref([])

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        searchtext: searchtext.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { data, dataDetail } = await reportDisPensing(payload)
      dataTableList.value = data
      data2.value = dataDetail
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('report_dispensing_drug')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  Is Data 1  */
      const fakeData = JSON.parse(JSON.stringify(dataTableList.value))
      let dataExport = fakeData.map(item => {
        item.order_serve = item.serve_code || item.order_id
        delete item.drug_id_pri
        delete item.serve_id
        delete item.serve_code
        delete item.order_serve
        delete item.order_id_pri
        delete item.order_id

        return item
      })
      dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))

      const Heading = [
        [`${i18n.t('report_dispensing_drug')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}`],
        [
          '#',
          i18n.t('group'),
          i18n.t('code'),
          i18n.t('name'),
          i18n.t('cost_price'),
          i18n.t('receipt'),
          i18n.t('cut_course'),
          i18n.t('transfer_out'),
          i18n.t('overall'),
          i18n.t('unit'),
          i18n.t('net_amount'),
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'drug_category_name',
          'drug_id',
          'drug_name',
          'drugorder_cost',
          'receipt',
          'cut_course',
          'transfer_out',
          'sum_out',
          'drug_unit',
          'total',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
      const wscols = [
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('report_dispensing_drug').replace('/', ' ')}`)

      /*  Is Data2 */
      const Heading2 = [
        [
          `${i18n.t('list_of_customers_by_sales')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(
            end,
          )}`,
        ],
        [
          '#',
          i18n.t('service_user_number'),
          i18n.t('receipt_code'),
          `${i18n.t('drug_code')}/${i18n.t('equipment')}`,
          `${i18n.t('drug_name')}/${i18n.t('equipment')}`,
          i18n.t('lot'),
          i18n.t('lot_no'),
          i18n.t('amount'),
          i18n.t('unit'),
          i18n.t('price'),
          i18n.t('customer_code'),
          i18n.t('name'),
          i18n.t('tel'),
          i18n.t('id_card'),
          i18n.t('gender'),
          i18n.t('email'),
          i18n.t('blood_type'),
          i18n.t('birthday'),
          i18n.t('age'),
          i18n.t('address'),
          i18n.t('tag'),
          i18n.t('doctor_inspector'),
          i18n.t('type'),
          i18n.t('date'),
          i18n.t('adjunct'),
        ],
      ]
      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, await removeComma(data2.value), {
        header: [
          'number',
          'serve_code',
          'order_id',
          'drug_id',
          'drug_name',
          'druglot_name',
          'drugorder_id',
          'drughistory_out',
          'drug_unit',
          'orderdetail_total',
          'customer_id',
          'customer_fullname',
          'customer_tel',
          'customer_idcard',
          'customer_gender',
          'customer_email',
          'customer_blood',
          'customer_birthdate',
          'customer_age',
          'customer_address',
          'customer_tags',
          'user_fullname',
          'type',
          'drughistory_date',
          'comment',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 24 } }]
      const wscols2 = [
        { wch: 5 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
        { wch: 25 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 8 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 5 },
        { wch: 25 },
        { wch: 10 },
        { wch: 15 },
        { wch: 8 },
        { wch: 50 },
        { wch: 15 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2
      XLSX.utils.book_append_sheet(wb, ws2, `${i18n.t('list_of_customers_by_sales').replace('/', ' ')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      searchtext,
      addPayload,
      exportExcel,
      columns,
      dataTableList,
      options,
      loading,
      header,
      payload,
      exportLoading,
      mdiFileExcelOutline,
    }
  },
}
</script>
